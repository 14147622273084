import React, { useEffect, useState } from 'react';
import { Container, Grid, makeStyles, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { typo } from '../../utils/typo';
import { AgoraPaginationFilter, AgoraSearchInput, AgoraProductListInnerTable, AgoraSelectSearch } from 'ui-components';
import CustomTableContainer from '../../components/table/CustomTableContainer';
import CustomTableBody from '../../components/table/CustomTableBody';
import CustomTableHead from '../../components/table/CustomTableHead';
import { RootState } from '../../redux/store';

import { useDebounce } from '../../hooks/useDebounce';
import { Store, PriceList } from 'types-library';
import { useTranslation } from 'react-i18next';
import {
  getMoreStoresBySupplierList,
  getProductsListBySupplier,
  getStoresBySupplierList,
  putPriceList,
} from '../../redux/slices/priceListSlice';
import { getUnitsThunk } from '../../redux/slices/supplierSlice';
import AddProductPricelist from '../../components/product/AddProductPricelist';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
  },
  gridTable: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  addPricelistContainer: {
    backgroundColor: 'white',
    marginTop: '2rem',
    padding: '1rem',
    borderRadius: '0.5rem',
    border: '1px solid #E3E3E3',
  },
}));

const RESULTS_PER_PAGE = 10;

type SupplierProductsPageProps = unknown;

const SupplierProductsList: React.FC<SupplierProductsPageProps> = ({}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { productsPriceList } = useSelector((state: RootState) => state.priceList.data);
  const loading = useSelector((state: RootState) => state.priceList.meta.loadingPricelist);
  const stores = useSelector((state: RootState) => state.priceList.data.stores);
  const storesCount = useSelector((state: RootState) => state.priceList.meta.paginationStores);
  const maxProductsCount = useSelector((state: RootState) => state.priceList.meta.paginationProducts);
  const units = useSelector((state: RootState) => state.supplier.units);

  const [page, setPage] = useState(0);
  const [pageStore, setPageStore] = useState(0);
  const [editProductDrawer, setEditProductDrawer] = useState<boolean>(false);

  const [searchStore, setSearchStore] = useState<string>('');
  const [selectedStore, setSelectedStore] = useState<Store | null>(null);
  const [searchInput, setSearchInput] = useState<string>('');
  const debouncedSearchText = useDebounce(searchInput, 500);

  useEffect(() => {
    const supplierId = location.pathname.split('/')[2];
    if (supplierId) {
      dispatch(getUnitsThunk({ supplierId: supplierId || '' }));
    }
  }, [dispatch]);

  useEffect(() => {
    const supplierId = location.pathname.split('/')[2];
    dispatch(
      getProductsListBySupplier({
        reqParams: {
          skip: page * RESULTS_PER_PAGE,
          limit: RESULTS_PER_PAGE,
          searchText: debouncedSearchText,
          storeId: selectedStore ? selectedStore._id : undefined,
        },
        supplierId: supplierId,
      }),
    );
  }, [dispatch, page, debouncedSearchText, editProductDrawer, selectedStore]);

  useEffect(() => {
    const supplierId = location.pathname.split('/')[2];
    dispatch(
      getStoresBySupplierList({
        reqParams: { skip: 0, limit: RESULTS_PER_PAGE, search: searchStore },
        supplierId,
      }),
    );
    setPageStore(0);
  }, [dispatch, searchStore]);

  const fetchMoreStores = () => {
    const supplierId = location.pathname.split('/')[2];
    dispatch(
      getMoreStoresBySupplierList({
        reqParams: { skip: pageStore * RESULTS_PER_PAGE, limit: RESULTS_PER_PAGE, search: searchStore },
        supplierId,
      }),
    );
    setPageStore(pageStore + 1);
  };

  // const openEditProduct = (product: Product) => {
  //   // dispatch(setSelectedProduct(product));
  //   setEditProductDrawer(true);
  // };
  //
  // const closeEditProduct = () => {
  //   setEditProductDrawer(false);
  //   // dispatch(setClearState(true));
  // };

  const handleUpdateProduct = (priceList: PriceList) => {
    const params = { priceListId: priceList._id, priceMap: priceList.priceMap };
    dispatch(putPriceList(params));
  };

  const showProducts = () => {
    return (
      <Grid container className={classes.root}>
        <Grid container alignItems={'center'}>
          <Grid item xs={9} md={3}>
            <Typography style={typo(18, 'semi')}>{t('price-list')}</Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.addPricelistContainer}>
          <Grid xs={12}>
            <Typography style={typo(18, 'semi')}>{t('add-price-list')}</Typography>
          </Grid>
          <AddProductPricelist />
        </Grid>
        <Grid item xs={12} className={classes.gridTable}>
          <CustomTableContainer>
            <CustomTableHead>
              <Grid container justify={'flex-end'} alignItems={'center'} wrap={'nowrap'}>
                <Grid item container style={{ flex: 0 }} wrap={'nowrap'} justify={'flex-end'}>
                  <Grid item>
                    <AgoraSelectSearch
                      handleSearch={(search: string) => setSearchStore(search)}
                      selectedStore={selectedStore}
                      setSelectedStore={(store: Store | null) => setSelectedStore(store)}
                      handleFetchMore={fetchMoreStores}
                      stores={stores}
                      hasMore={storesCount > stores.length}
                    />
                  </Grid>
                  <Grid item>
                    <AgoraSearchInput
                      border={'1px solid #E3E3E3'}
                      placeholder={t('search.products')}
                      changeSearch={setSearchInput}
                      scroll={false}
                    />
                  </Grid>
                  <Grid item>
                    <AgoraPaginationFilter
                      page={page}
                      setPage={setPage}
                      pageSize={RESULTS_PER_PAGE}
                      allCount={maxProductsCount}
                      style={{ marginLeft: 16 }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </CustomTableHead>
            <CustomTableBody>
              <AgoraProductListInnerTable
                units={units}
                loading={loading}
                newEditProduct={(p: PriceList) => handleUpdateProduct(p)}
                productsList={productsPriceList}
              />
            </CustomTableBody>
          </CustomTableContainer>
        </Grid>
      </Grid>
    );
  };

  return (
    <Container maxWidth={'lg'} className={classes.root}>
      <Grid container>{showProducts()}</Grid>
    </Container>
  );
};

export default SupplierProductsList;
