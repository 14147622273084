/* eslint-disable react/react-in-jsx-scope */
import { Box, Container, Grid, IconButton, Typography, makeStyles } from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AgoraButton, AgoraSearchPicker, AgoraTextField } from 'ui-components';
import { ReactComponent as CloseIcon } from '../../assets/svgs/x.svg';
import { ICreateBuyerBody } from '../../lib/api/http/types/ICreateBuyerBody';
import { getCountriesThunk } from '../../redux/slices/appSlice';
import { postBuyerThunk } from '../../redux/slices/buyerSlice';
import { RootState } from '../../redux/store';
import { typo } from '../../utils/typo';
import BoxContainer from '../common/BoxContainer';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#F1F1F1',
    width: '100%',
    maxWidth: 1200,
  },
  appBar: {
    paddingTop: 20,
    paddingRight: 16,
  },
  buttonClose: {
    padding: 0,
  },
  container: {
    '&.MuiContainer-root': {
      paddingRight: 16,
      marginLeft: 80,
      paddingTop: 120,
    },
  },
  title: {
    ...typo(32, 'semi'),
    color: theme.palette.primary.main,
  },
}));

type AddBuyerModalProps = {
  closeDrawer: () => void;
};

const AddBuyerModal: FC<AddBuyerModalProps> = ({ closeDrawer }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const countries = useSelector((state: RootState) => state.app.countries);
  const countriesLoading = useSelector((state: RootState) => state.app.countriesLoading);

  const [fullName, setFullName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [country, setCountry] = useState<string>('GR');

  const [countriesSearchText, setCountriesSearchText] = useState('');

  useEffect(() => {
    dispatch(getCountriesThunk({ searchText: countriesSearchText }));
  }, [countriesSearchText, dispatch]);

  const createBuyer = () => {
    const params: ICreateBuyerBody = {
      name: fullName,
      email: email,
      country,
    };
    dispatch(postBuyerThunk(params));
    closeDrawer();
  };

  const appBar = () => {
    return (
      <Grid container alignItems={'center'} justify={'flex-start'} className={classes.appBar}>
        <Grid container item xs={1} alignItems={'center'} justify={'center'}>
          <IconButton className={classes.buttonClose} onClick={() => closeDrawer()}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid container item xs={6} justify={'flex-start'} alignItems={'center'}>
          <Typography className={classes.title}>{t('add.buyer')}</Typography>
        </Grid>
        <Grid container item xs={5} justify={'flex-end'} alignItems={'center'} spacing={2}>
          <Grid item xs={4}>
            <AgoraButton
              title={t('cancel')}
              padding={14}
              variant={'outlined'}
              handleClick={closeDrawer}
              background={'#FFFFFF'}
              color={''}
            />
          </Grid>
          <Grid item xs={8} md={7} lg={6}>
            <AgoraButton title={t('createAccount.buyer')} padding={14} handleClick={() => createBuyer()} />
          </Grid>
        </Grid>
      </Grid>
    );
  };
  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid container item xs={12} style={{ marginTop: 12 }}>
          {appBar()}
        </Grid>
        <Container maxWidth={'md'} className={classes.container}>
          <Grid container item justify={'space-between'} spacing={2} xs={12}>
            <Grid item xs={6}>
              <BoxContainer title={t('data.companyInfo')}>
                <Grid container alignItems={'center'}>
                  <Grid container item alignItems={'center'} justify={'center'} xs={12}>
                    <Grid item xs={12}>
                      <AgoraTextField
                        label={t('nameSur')}
                        radius={'8px 8px 0px 0px'}
                        text
                        value={fullName}
                        setValue={setFullName}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <AgoraTextField text label={'Email'} radius={'0px'} value={email} setValue={setEmail} />
                    </Grid>
                    <Grid item xs={12}>
                      <AgoraSearchPicker
                        loading={countriesLoading}
                        data={countries}
                        valueIdentifier="code"
                        labelIdentifier="name"
                        placeholder={t('countrySur')}
                        value={country}
                        searchText={countriesSearchText}
                        setSearchText={setCountriesSearchText}
                        onChange={(v) => setCountry(v)}
                        baseContainerStyle={{ border: '1px solid rgb(227, 227, 227)' }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </BoxContainer>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Box>
  );
};

export default AddBuyerModal;
