import { IconButton, Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { closeToast } from '../../redux/slices/appSlice';
import { RootState } from '../../redux/store';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiSnackbarContent-root': {
      fontWeight: 600,
      backgroundColor: '#50BC8F',
      color: '#FFFFFF',
    },
  },
}));

type ToastProps = unknown;

const Toast: React.FC<ToastProps> = ({}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { open, text, success } = useSelector((state: RootState) => state.app.toast);

  const handleClose = () => {
    dispatch(closeToast());
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      className={success ? classes.root : ''}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      message={t(text)}
      action={
        <React.Fragment>
          <IconButton size="small" aria-label="close" color="secondary" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    />
  );
};

export default Toast;
