import React from 'react';
import Routes from './Routes/Routes';
import { Box } from '@material-ui/core';
import Toast from './components/structure/Toast';
import initialize from './utils/initialize';

initialize();

function App() {
  return (
    <React.Suspense fallback={null}>
      <Box>
        <Routes />
        <Toast />
      </Box>
    </React.Suspense>
  );
}

export default App;
