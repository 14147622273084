import {
  Box,
  CircularProgress,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import FavoriteOutlinedIcon from '@material-ui/icons/FavoriteOutlined';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Product, Supplier } from 'types-library';
import { AgoraToggleSort } from 'ui-components';
import { ReactComponent as DefaultImage } from '../../assets/svgs/default-product.svg';
import { RootState } from '../../redux/store';
import { typo } from '../../utils/typo';

const useStyles = makeStyles((theme) => ({
  body: {
    '& > *:last-child .MuiTableCell-root': {
      borderBottom: 'unset',
    },
    '& .MuiTableCell-root': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  toggleSelect: {
    position: 'relative',
    right: -12,
  },
  row: {
    '& .MuiTableCell-root:not(:last-child)': {
      borderRight: '1px solid #E3E3E3',
    },
  },
  selectCell: {
    height: '100%',
    marginRight: theme.spacing(1),
    borderRight: '1px solid #E3E3E3',
  },
  extraSpace: {
    minWidth: 250,
  },
  storeImg: {
    width: 40,
    height: 40,
    maxWidth: 40,
    maxHeight: 40,
    overflow: 'hidden',
    border: '1px solid ' + theme.palette.secondary.dark,
    borderRadius: '40%',
    marginRight: theme.spacing(1),
  },
  defaultIcon: {
    padding: '4px 6px 4px 4px',
  },
  actionButton: {
    cursor: 'pointer',
    userSelect: 'none',
    transition: 'all 0.4s',
  },
  actionButtonActive: {
    background: theme.palette.secondary.main,
  },

  image: {
    width: 40,
    height: 40,
    padding: 5,
    objectFit: 'cover',
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid ' + theme.palette.secondary.dark,
    borderRadius: '40%',
    marginRight: theme.spacing(1),
  },
}));

type InnerProductFavouriteTableProps = {
  products: Product[];
  loading?: boolean;
  favourites: string[];
  sortingName: boolean | null;
  setSortingName: (b: boolean | null) => void;
  updateFavourites: (newFav: string[]) => void;
};

const InnerProductFavouriteTable: React.FC<InnerProductFavouriteTableProps> = ({
  products,
  loading,
  favourites,
  updateFavourites,
  sortingName,
  setSortingName,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const units = useSelector((state: RootState) => state.supplier.units);

  const onClickFavourite = (id: string, alreadyFav: boolean) => {
    let newList = [];
    if (alreadyFav) {
      newList = favourites.filter((f) => f !== id);
    } else {
      newList = [...favourites, id];
    }
    updateFavourites(newList);
  };

  const getCellHeaderContent = (params: {
    text: string;
    onClick?: (object: any) => void;
    extra?: boolean;
    state?: null | boolean;
  }) => {
    return (
      <Grid
        container
        wrap={'nowrap'}
        justify={'space-between'}
        className={params.extra ? classes.extraSpace : undefined}
      >
        <Grid item>
          <Typography style={typo(12, 'bold')}>{params.text}</Typography>
        </Grid>
        {params.onClick && (
          <Grid item className={classes.toggleSelect}>
            <AgoraToggleSort onClick={params.onClick} state={params.state} />
          </Grid>
        )}
      </Grid>
    );
  };

  const getTableHead = () => {
    return (
      <TableHead style={{ background: '#F6F6F6' }}>
        <TableRow className={classes.row}>
          <TableCell>
            <Grid container wrap={'nowrap'} alignItems={'center'}>
              {getCellHeaderContent({ text: t('name.product'), state: sortingName, onClick: setSortingName })}
            </Grid>
          </TableCell>
          <TableCell>{getCellHeaderContent({ text: t('name.supplier') })}</TableCell>
          <TableCell>{getCellHeaderContent({ text: t('detailsProduct.type') })}</TableCell>
          <TableCell>{getCellHeaderContent({ text: t('detailsProduct.cost') })}</TableCell>
          <TableCell>{getCellHeaderContent({ text: t('actions') })}</TableCell>
        </TableRow>
      </TableHead>
    );
  };

  const getRow = (product: Product) => {
    // const unit = getUnitPricesTypes(product);
    const price = 0; // product.pricesPerUnit[unit as UNIT_MEASURE];
    const supplier = product.supplierId as unknown as Supplier;

    let isFavoured = false;
    if (favourites) {
      const found = favourites.find((f) => f === product._id);
      if (found) isFavoured = true;
    }

    return (
      <TableRow className={classes.row}>
        <TableCell padding={'none'}>
          <Grid container wrap={'nowrap'} style={{ padding: 8 }}>
            <Grid item>
              {product.productImage ? (
                <img src={product.productImage} alt={'store-image'} className={classes.storeImg} />
              ) : (
                <Box className={classes.image}>
                  <DefaultImage />
                </Box>
              )}
            </Grid>
            <Grid item container>
              <Grid item xs={12}>
                <Typography style={typo(14, 'semi')}>{product.name}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography style={{ ...typo(10, 'semi'), color: '#C6C6C6' }}>{product.brandName}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>
          <Typography style={typo(12, 'bold')}>
            {supplier?.companyDetails ? supplier.companyDetails.name : 'N/A'}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography style={typo(12, 'bold')}>{i18n.language === 'gr' ? '' : ''}</Typography>
        </TableCell>
        <TableCell>
          <Typography style={typo(12, 'bold')}> {price === 0 ? 'N/A' : '€ ' + price}</Typography>
        </TableCell>
        <TableCell className={clsx(classes.actionButton)} onClick={() => onClickFavourite(product._id, isFavoured)}>
          <Grid container justify={'center'} style={{ color: theme.palette.info.dark }}>
            {isFavoured ? <FavoriteOutlinedIcon /> : <FavoriteBorderOutlinedIcon />}
          </Grid>
        </TableCell>
      </TableRow>
    );
  };

  const getTableBody = () => {
    return (
      <TableBody className={classes.body}>
        {!loading ? (
          products.length === 0 ? (
            <TableRow>
              <TableCell padding={'none'} colSpan={9}>
                <Grid container style={{ padding: '16px 8px', textAlign: 'center' }} justify={'center'}>
                  <Typography style={{ ...typo(16, 'regular'), color: '#999' }}>{t('msg.data')}</Typography>
                </Grid>
              </TableCell>
            </TableRow>
          ) : (
            products.map((product, index) => <React.Fragment key={index}>{getRow(product)}</React.Fragment>)
          )
        ) : (
          <TableRow>
            <TableCell padding={'none'} colSpan={9}>
              <Grid container style={{ padding: '16px 8px', textAlign: 'center' }} justify={'center'}>
                <CircularProgress style={{ width: 24, height: 24, color: '#999' }} />
              </Grid>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    );
  };

  return (
    <Table>
      {getTableHead()}
      {getTableBody()}
    </Table>
  );
};

export default InnerProductFavouriteTable;
