import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, makeStyles } from '@material-ui/core';
import CustomTableContainer from '../../components/table/CustomTableContainer';
import CustomTableBody from '../../components/table/CustomTableBody';
import CustomTableHead from '../../components/table/CustomTableHead';
import TableTabs from '../../components/table/TableTabs';
import { AgoraPaginationFilter, AgoraSearchInput } from 'ui-components';
import OrdersInnerTable from '../../components/table/OrdersInnerTable';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { setLayout } from '../../redux/slices/layoutSlice';
import { getOrdersBySupplier } from '../../redux/slices/supplierSlice';
import { useLocation } from 'react-router-dom';
import { ORDER_STATUS } from 'types-library';
import { useDebounce } from '../../hooks/useDebounce';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
}));

const RESULTS_PER_PAGE = 10;

type SupplierOrdersPageProps = unknown;

const SupplierOrdersPage: React.FC<SupplierOrdersPageProps> = ({}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [tab, setTab] = useState<number>(0);
  const [refreshCounter, setRefreshCounter] = useState<number>(0);
  const location = useLocation();
  const [searchInput, setSearchInput] = useState<string>('');
  const debouncedSearchText = useDebounce(searchInput, 500);
  const [createdDateSort, setCreatedDateSort] = useState<boolean | null>(false);
  const [deliveryDateSort, setDeliveryDateSort] = useState<boolean | null>(null);
  const [codeSort, setCodeSort] = useState<boolean | null>(null);
  const [priceSort, setPriceSort] = useState<boolean | null>(null);

  const orders = useSelector((state: RootState) => state.supplier.orders);
  const maxOrdersCount = useSelector((state: RootState) => state.supplier.pagination.orders);
  const loading = useSelector((state: RootState) => state.supplier.loading);

  const [page, setPage] = useState<number>(0);

  const tabs = [
    t('status.all'),
    t('status.pending'),
    t('status.accepted'),
    t('status.delivered'),
    t('status.rejected'),
    t('status.canceled'),
  ];

  // Layout Config Effect
  useEffect(() => {
    dispatch(setLayout({ header: true, mainMenu: true, footer: true }));
  }, [dispatch]);

  useEffect(() => {
    const supplierId = location.pathname.split('/')[2];
    let status: ORDER_STATUS | null = null;
    switch (tab) {
      case 0:
        status = null;
        break;
      case 1:
        status = ORDER_STATUS.PENDING_RFQ;
        break;
      case 2:
        status = ORDER_STATUS.ACCEPTED;
        break;
      case 3:
        status = ORDER_STATUS.DELIVERED;
        break;
      case 4:
        status = ORDER_STATUS.REJECTED;
        break;
      case 5:
        status = ORDER_STATUS.CANCELED;
        break;
    }

    let sort = '';
    if (deliveryDateSort !== null) {
      sort += deliveryDateSort ? 'deliveryDate' : '-deliveryDate';
    } else if (createdDateSort !== null) {
      sort += createdDateSort ? 'createdAt' : '-createdAt';
    } else if (codeSort !== null) {
      sort += codeSort ? 'orderNumber' : '-orderNumber';
    } else if (priceSort !== null) {
      sort += priceSort ? 'cost' : '-cost';
    }

    dispatch(
      getOrdersBySupplier({
        reqParams: { skip: page * RESULTS_PER_PAGE, limit: RESULTS_PER_PAGE, sort, search: debouncedSearchText },
        supplierId,
        status,
      }),
    );
  }, [
    dispatch,
    location.pathname,
    page,
    tab,
    debouncedSearchText,
    deliveryDateSort,
    createdDateSort,
    codeSort,
    priceSort,
    refreshCounter,
  ]);

  const refreshOrders = () => {
    setRefreshCounter((prev) => prev + 1);
  };

  const resetSorts = () => {
    setDeliveryDateSort(null);
    setCreatedDateSort(null);
    setCodeSort(null);
    setPriceSort(null);
  };

  const handleSetDeliverySort = (sort: boolean | null) => {
    resetSorts();
    setDeliveryDateSort(sort);
  };

  const handleSetCreatedSort = (sort: boolean | null) => {
    resetSorts();
    setCreatedDateSort(sort);
  };
  const handleSetCodeSort = (sort: boolean | null) => {
    resetSorts();
    setCodeSort(sort);
  };

  const handleSetPriceSort = (sort: boolean | null) => {
    resetSorts();
    setPriceSort(sort);
  };

  return (
    <Container maxWidth={'lg'}>
      <Box className={classes.root}>
        <CustomTableContainer>
          <CustomTableHead>
            <Grid container justify={'space-between'} alignItems={'center'} wrap={'nowrap'}>
              <Grid item>
                <TableTabs tabs={tabs} selectedTab={tab} setSelectedTab={setTab} />
              </Grid>
              <Grid item container style={{ flex: 0 }} wrap={'nowrap'} justify={'flex-end'}>
                <Grid item>
                  <AgoraSearchInput
                    border={'1px solid #E3E3E3'}
                    placeholder={t('search.orders')}
                    changeSearch={setSearchInput}
                    scroll={false}
                  />
                </Grid>
                <Grid item>
                  <AgoraPaginationFilter
                    page={page}
                    setPage={setPage}
                    pageSize={RESULTS_PER_PAGE}
                    allCount={maxOrdersCount}
                    style={{ marginLeft: 16 }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </CustomTableHead>
          <CustomTableBody>
            <OrdersInnerTable
              orders={orders}
              refreshOrders={refreshOrders}
              deliveryDateSort={deliveryDateSort}
              setDeliveryDateSort={handleSetDeliverySort}
              createdDateSort={createdDateSort}
              setCreatedDateSort={handleSetCreatedSort}
              costSort={priceSort}
              setCostSort={handleSetPriceSort}
              codeSort={codeSort}
              setCodeSort={handleSetCodeSort}
              loading={loading}
            />
          </CustomTableBody>
        </CustomTableContainer>
      </Box>
    </Container>
  );
};

export default SupplierOrdersPage;
