import React, { FC, useEffect, useState } from 'react';
import { Box, Container, Grid, IconButton, makeStyles, AppBar, Typography } from '@material-ui/core';
import { ReactComponent as CloseIcon } from '../../assets/svgs/x.svg';
import { typo } from '../../utils/typo';
import { AgoraButton, AgoraTextField } from 'ui-components';
import BoxContainer from '../common/BoxContainer';
import { Supplier } from 'types-library';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#F1F1F1',
    width: '100%',
    maxWidth: 1200,
    paddingTop: 65,
  },
  appBar: {
    width: '100%',
    maxWidth: 1190,
    paddingTop: 20,
    paddingRight: 16,
    paddingBottom: 20,
    zIndex: 900,
    '&.MuiAppBar-root': {
      background: '#F1F1F1',
      '& .MuiPaper-root': {
        boxShadow: '0px 1px 1px rgb(0, 0, 0, 0.25 )',
      },
    },
    '&.MuiPaper-elevation4': {
      boxShadow: 'none',
    },
  },
  buttonClose: {
    padding: 0,
  },
  title: {
    ...typo(32, 'semi'),
    color: theme.palette.primary.main,
  },
  container: {
    '&.MuiContainer-root': {
      paddingRight: 16,
      marginLeft: 80,
      paddingTop: 25,
    },
  },
}));

type EditSupplierModalProps = {
  closeDrawer: () => void;
  selectedSupplier: Supplier;
  handleEditSupplier: (newSupplier: Supplier) => void;
};

const EditSupplierModal: FC<EditSupplierModalProps> = ({ closeDrawer, selectedSupplier, handleEditSupplier }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [supplierFullName, setSupplierFullName] = useState<string>('');
  const [supplierOccupation, setSupplierOccupation] = useState<string>('');
  const [supplierAddress, setSupplierAddress] = useState<string>('');
  const [supplierCity, setSupplierCity] = useState<string>('');
  const [supplierCountry, setSupplierCountry] = useState<string>('');
  const [supplierPhone, setSupplierPhone] = useState<string>('');

  const [companyName, setCompanyName] = useState<string>('');
  const [companyAfm, setCompanyAfm] = useState<string>('');
  const [companyDescription, setCompanyDescription] = useState<string>('');
  const [companyAddress, setCompanyAddress] = useState<string>('');
  const [companyCity, setCompanyCity] = useState<string>('');
  const [companyPostCode, setCompanyPostCode] = useState<string>('');

  useEffect(() => {
    const {
      supplierDetails = { fullName: '', occupation: '', address: '', city: '', phone: '' },
      companyDetails = { name: '', afm: '', description: '', address: '', country: 'GR', city: '', postCode: '' },
    } = selectedSupplier ? selectedSupplier : {};

    setSupplierFullName(supplierDetails.fullName);
    setSupplierOccupation(supplierDetails.occupation);
    setSupplierAddress(supplierDetails.address);

    setSupplierCity(supplierDetails.city);
    setSupplierPhone(supplierDetails.phone);
    setCompanyName(companyDetails.name);
    setCompanyAfm(companyDetails.afm);
    setCompanyDescription(companyDetails.description);
    setCompanyAddress(companyDetails.address);
    setSupplierCountry(companyDetails.country);
    setCompanyCity(companyDetails.city);
    setCompanyPostCode(companyDetails.postCode);
  }, [selectedSupplier]);

  const onClickEdit = () => {
    handleEditSupplier({
      ...selectedSupplier,
      supplierDetails: {
        fullName: supplierFullName,
        occupation: supplierOccupation,
        address: supplierAddress,
        city: supplierCity,
        phone: supplierPhone,
      },
      companyDetails: {
        name: companyName,
        afm: companyAfm,
        description: companyDescription,
        address: companyAddress,
        country: supplierCountry,
        city: companyCity,
        postCode: companyPostCode,
      },
    });
    closeDrawer();
  };

  const appBar = () => {
    return (
      <Box>
        <AppBar position="fixed" className={classes.appBar}>
          <Grid container alignItems={'center'} justify={'flex-start'} wrap={'nowrap'}>
            <Grid container style={{ flex: 0, marginInline: 30 }} item alignItems={'center'} justify={'center'}>
              <IconButton className={classes.buttonClose} onClick={() => closeDrawer()}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid container item justify={'flex-start'} alignItems={'center'}>
              <Typography className={classes.title}>{t('edit.supplier')}</Typography>
            </Grid>
            <Grid container item justify={'flex-end'} alignItems={'center'}>
              <Grid item>
                <AgoraButton
                  title={t('cancel')}
                  padding={10}
                  variant={'outlined'}
                  handleClick={closeDrawer}
                  background={'white'}
                  color={'black'}
                />
              </Grid>
              <Grid item style={{ padding: '0 16px' }}>
                <AgoraButton title={t('save')} padding={10} handleClick={onClickEdit} />
              </Grid>
            </Grid>
          </Grid>
        </AppBar>
      </Box>
    );
  };
  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid container item xs={12} style={{ marginTop: 12 }}>
          {appBar()}
        </Grid>
        <Container maxWidth={'md'} className={classes.container}>
          <Grid container item justify={'space-between'} spacing={2} xs={12}>
            <Grid item xs={6}>
              <BoxContainer title={t('data.supplierInfo')}>
                <Grid container alignItems={'center'}>
                  <Grid container item alignItems={'center'} justify={'center'} xs={12}>
                    <Grid item xs={12}>
                      <AgoraTextField
                        label={t('nameSur')}
                        radius={'8px 8px 0px 0px'}
                        text
                        value={supplierFullName}
                        setValue={setSupplierFullName}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <AgoraTextField
                        text
                        label={t('contactInfo.activity')}
                        radius={'0px'}
                        value={supplierOccupation}
                        setValue={setSupplierOccupation}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <AgoraTextField
                        text
                        label={t('contactInfo.address')}
                        radius={'0px'}
                        value={supplierAddress}
                        setValue={setSupplierAddress}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <AgoraTextField
                        text
                        label={t('contactInfo.city')}
                        radius={'0px'}
                        value={supplierCity}
                        setValue={setSupplierCity}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <AgoraTextField
                        text
                        label={t('contactInfo.phone')}
                        radius={'0px 0px 8px  8px'}
                        value={supplierPhone}
                        setValue={setSupplierPhone}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 32 }}>
                    <Typography style={typo(18, 'semi')}>{t('data.companyInfo')}</Typography>
                  </Grid>
                  <Grid container item alignItems={'center'} justify={'center'} xs={12} style={{ marginTop: 16 }}>
                    <Grid item xs={12}>
                      <AgoraTextField
                        label={t('name.company')}
                        radius={'8px 8px 0px 0px'}
                        text
                        value={companyName}
                        setValue={setCompanyName}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <AgoraTextField
                        text
                        label={t('description.company')}
                        radius={'0px'}
                        value={companyDescription}
                        setValue={setCompanyDescription}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <AgoraTextField
                        text
                        label={t('contactInfo.afm')}
                        radius={'0px'}
                        value={companyAfm}
                        setValue={setCompanyAfm}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <AgoraTextField
                        text
                        label={t('contactInfo.address')}
                        radius={'0px'}
                        value={companyAddress}
                        setValue={setCompanyAddress}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <AgoraTextField
                        text
                        label={t('contactInfo.city')}
                        radius={'0px'}
                        value={companyCity}
                        setValue={setCompanyCity}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <AgoraTextField
                        text
                        label={t('contactInfo.postCode')}
                        radius={'0px 0px 8px  8px'}
                        value={companyPostCode}
                        setValue={setCompanyPostCode}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </BoxContainer>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Box>
  );
};

export default EditSupplierModal;
