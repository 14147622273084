import {
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Store } from 'types-library';
import { AgoraToggleSort } from 'ui-components';
import DefaultImage from '../../assets/svgs/default-product.svg';
import { ReactComponent as EyeIcon } from '../../assets/svgs/eye.svg';
import { ReactComponent as TrashCanIcon } from '../../assets/svgs/trash-can.svg';
import { openToast } from '../../redux/slices/appSlice';
import { getStoreIdThunk } from '../../redux/slices/storeSlice';
import { typo } from '../../utils/typo';

const useStyles = makeStyles((theme) => ({
  root: {
    '& hr': {
      display: 'inline',
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: '#E3E3E3',
    },
  },
  body: {
    '& > *:last-child .MuiTableCell-root': {
      borderBottom: 'unset',
    },
    '& .MuiTableCell-root': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  row: {
    '& .MuiTableCell-root:not(:last-child)': {
      borderRight: '1px solid #E3E3E3',
    },
  },
  toggleSelect: {
    position: 'relative',
    right: -12,
  },
  selectCell: {
    height: '100%',
    marginRight: theme.spacing(1),
    borderRight: '1px solid #E3E3E3',
  },
  extraSpace: {
    minWidth: 250,
  },
  storeImg: {
    width: 32,
    height: 32,
    maxWidth: 32,
    maxHeight: 32,
    overflow: 'hidden',
    border: '0.3px solid rgba(0, 0, 0, 0.47)',
    borderRadius: '30%',
    marginRight: theme.spacing(1),
  },
  defaultIcon: {
    padding: '4px 6px 4px 4px',
  },
  actionButton: {
    textAlign: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    background: theme.palette.primary.main,
    margin: theme.spacing(0, 1),
    padding: theme.spacing(1, 3),
    borderRadius: 8,
  },
}));

type InnerBuyerStoresTableProps = {
  stores: Store[];
  deleteStore: (id: string) => void;
  toggleName: null | boolean;
  setToggleName: (name: boolean) => void;
  loading?: boolean;
};

const InnerBuyerStoresTable: React.FC<InnerBuyerStoresTableProps> = ({
  stores,
  deleteStore,
  toggleName,
  setToggleName,
  loading,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const handleSelectStore = (store: Store) => {
    if (store._id) {
      dispatch(getStoreIdThunk(store._id));
      history.push(`/buyers/${store._id}/suppliers`);
    }
  };

  const getCellHeaderContent = (params: {
    text: string;
    onClick?: (object: any) => void;
    extra?: boolean;
    state?: null | boolean;
  }) => {
    return (
      <Grid
        container
        wrap={'nowrap'}
        justify={'space-between'}
        className={params.extra ? classes.extraSpace : undefined}
      >
        <Grid item>
          <Typography style={typo(12, 'bold')}>{params.text}</Typography>
        </Grid>
        {params.onClick && (
          <Grid item>
            <AgoraToggleSort onClick={params.onClick} state={params.state} />
          </Grid>
        )}
      </Grid>
    );
  };

  const getTableHead = () => {
    return (
      <TableHead style={{ background: '#F6F6F6' }}>
        <TableRow className={classes.row}>
          <TableCell>
            <Grid container wrap={'nowrap'} alignItems={'center'}>
              {getCellHeaderContent({ text: t('store-id') })}
            </Grid>
          </TableCell>
          <TableCell>
            <Grid container wrap={'nowrap'} alignItems={'center'}>
              {getCellHeaderContent({ text: t('name.store'), onClick: setToggleName, state: toggleName })}
            </Grid>
          </TableCell>
          <TableCell>{getCellHeaderContent({ text: t('contactInfo.address') })}</TableCell>
          <TableCell>{getCellHeaderContent({ text: 'Email' })}</TableCell>
          <TableCell>{getCellHeaderContent({ text: t('contactInfo.phone') })}</TableCell>
          <TableCell>{getCellHeaderContent({ text: t('actions') })}</TableCell>
        </TableRow>
      </TableHead>
    );
  };

  const getRow = (store: Store) => {
    const { storeImage = '', name = '', address = '', phone = '', buyer = '' } = store ? store : {};

    return (
      <TableRow className={classes.row}>
        <TableCell>
          <Typography
            style={{ ...typo(12, 'bold'), cursor: 'pointer' }}
            onClick={() => {
              navigator.clipboard.writeText(store._id || '');
              dispatch(openToast({ text: 'Copied' }));
            }}
          >
            {store._id}
          </Typography>
        </TableCell>
        <TableCell padding={'none'}>
          <Grid container alignItems="center" wrap={'nowrap'} style={{ paddingLeft: 12 }}>
            <Grid item>
              {storeImage ? (
                <img src={storeImage} alt={'store-image'} className={classes.storeImg} />
              ) : (
                <img src={DefaultImage} alt={'store-image'} className={clsx(classes.storeImg, classes.defaultIcon)} />
              )}
            </Grid>
            <Grid item>
              <Typography style={typo(14, 'semi')}>{name}</Typography>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>
          <Typography style={typo(12, 'bold')}>{address}</Typography>
        </TableCell>
        <TableCell>
          <Typography style={typo(12, 'bold')}>{buyer && buyer.user ? buyer.user.email : '-'}</Typography>
        </TableCell>
        <TableCell>
          <Typography style={typo(12, 'bold')}>{phone}</Typography>
        </TableCell>
        <TableCell>
          <Grid container alignItems="center" justify="center">
            <Grid item>
              <IconButton
                onClick={() => {
                  store._id ? deleteStore(store._id) : null;
                }}
              >
                <TrashCanIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <Divider orientation="vertical" />
            </Grid>
            <Grid item>
              <IconButton onClick={() => handleSelectStore(store)}>
                <EyeIcon />
              </IconButton>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    );
  };

  const getTableBody = () => {
    return (
      <TableBody className={classes.body}>
        {!loading ? (
          stores.length === 0 ? (
            <TableRow>
              <TableCell padding={'none'} colSpan={5}>
                <Grid container style={{ padding: '16px 8px', textAlign: 'center' }} justify={'center'}>
                  <Typography style={{ ...typo(16, 'regular'), color: '#999' }}>{t('msg.data')}</Typography>
                </Grid>
              </TableCell>
            </TableRow>
          ) : (
            stores.map((store, index) => <React.Fragment key={index}>{getRow(store)}</React.Fragment>)
          )
        ) : (
          <TableRow>
            <TableCell padding={'none'} colSpan={5}>
              <Grid container style={{ padding: '16px 8px', textAlign: 'center' }} justify={'center'}>
                <CircularProgress style={{ width: 24, height: 24, color: '#999' }} />
              </Grid>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    );
  };

  return (
    <Table className={classes.root}>
      {getTableHead()}
      {getTableBody()}
    </Table>
  );
};

export default InnerBuyerStoresTable;
