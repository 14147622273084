import { Box, Container, Divider, Grid, Typography, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import mime from 'mime';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Supplier } from 'types-library';
import { AgoraProfileAvatar, AgoraSettingsFieldItem } from 'ui-components';
import Switch from '../../components/common/Switch';
import DialogCancel from '../../components/dialog/DialogCancel';
import { useDebounce } from '../../hooks/useDebounce';
import { deleteSupplier, imageUpdateSupplier, updateSupplier } from '../../redux/slices/supplierSlice';
import { RootState } from '../../redux/store';
import { typo } from '../../utils/typo';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: 93,
  },
  box: {
    marginTop: 24,
    marginBottom: 16,
  },
  warningBox: {
    color: theme.palette.error.light,
  },
  editOptions: {
    fontSize: '16px',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  editIcon: {
    fontSize: '1.3em',
  },
  switch: {
    '&.MuiFormControlLabel-root': {
      background: theme.palette.success.main,
      // color: theme.palette.success.main,
    },
  },
}));

type SupplierSettingsPageProps = unknown;

const SupplierSettingsPage: React.FC<SupplierSettingsPageProps> = ({}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const supplier = useSelector((state: RootState) => state.supplier.selectedSupplier);
  const [uploadImage, setUploadImage] = useState<string>('');
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const initialState = {
    name: 'N/A',
    address: 'N/A',
    city: 'N/A',
    postCode: 'N/A',
    afm: '-',
    phone: '-',
    pending: false,
  };
  const [{ name, address, city, postCode, afm, phone, pending }, setSupplier] = useState(initialState);

  const debouncedPending = useDebounce(pending, 500);

  useEffect(() => {
    if (supplier) {
      reset();
    }
  }, [supplier]);

  useEffect(() => {
    if (supplier) {
      const tempSupplier: Supplier = {
        ...supplier,
        pending: pending,
      };
      dispatch(updateSupplier({ supplier: tempSupplier }));
    }
  }, [debouncedPending, supplier, pending]);

  const reset = () => {
    if (supplier && supplier.supplierLogo) {
      setUploadImage(supplier.supplierLogo);
    }
    setSupplier({
      name: supplier ? supplier.companyDetails.name : initialState.name,
      address: supplier ? supplier.companyDetails.address : initialState.address,
      city: supplier ? supplier.companyDetails.city : initialState.city,
      postCode: supplier ? supplier.companyDetails.postCode : initialState.postCode,
      afm: supplier ? supplier.companyDetails.afm : initialState.afm,
      phone: supplier ? supplier.supplierDetails.phone : initialState.phone,
      pending: supplier ? supplier.pending : initialState.pending,
    });
  };

  const handleUpdateSupplier = (attributeName: string, newValue: string) => {
    if (supplier) {
      const tempSupplier: Supplier = {
        ...supplier,
        companyDetails: { ...supplier.companyDetails },
        supplierDetails: { ...supplier.supplierDetails },
      };
      attributeName.split('.').reduce(function (a: any, b: string) {
        if (typeof a[b] === 'string') {
          a[b] = newValue;
        }
        return a[b];
      }, tempSupplier);
      dispatch(updateSupplier({ supplier: tempSupplier }));
    }
  };

  const handleImageChange = (img: string) => {
    const file = {
      folder: 'suppliers-logos',
      name: name.replace(/ /g, '') + '.' + mime.getExtension(img.split(',')[0]?.split(';')[0].split(':')[1]),
      data: img,
    };
    if (supplier) {
      dispatch(imageUpdateSupplier(file));
    }
  };

  const onClickDeleteSupplier = () => {
    setOpenDeleteModal(true);
  };

  const handleAcceptDelete = () => {
    if (supplier) {
      dispatch(deleteSupplier({ supplier, callback: () => history.replace('/suppliers') }));
    }
    setOpenDeleteModal(false);
    history.push(`/suppliers/`);
  };

  const handleCloseDelete = () => {
    setOpenDeleteModal(false);
  };

  return (
    <Container maxWidth={'md'} className={classes.container}>
      <Grid container className={classes.box} justify={'space-between'}>
        <AgoraProfileAvatar
          title={t('settings.logo')}
          imgSrc={supplier?.supplierLogo}
          changeImage={(value: string) => handleImageChange(value)}
          variant={'circular'}
        />
      </Grid>
      {supplier && (
        <>
          <Divider />
          <Grid container alignItems={'center'} justify={'space-between'} style={{ marginBlock: 12 }}>
            <Grid item style={{ marginTop: 8 }}>
              <Typography style={typo(16, 'bold')}> {t('statusSupplier')}</Typography>
              <Typography style={{ marginBlock: 12 }}>{pending ? t('active.inactive') : t('active.active')}</Typography>
            </Grid>
            <Grid item>
              <Switch
                toggle={pending}
                setToggle={(value: boolean) => setSupplier((prevState) => ({ ...prevState, pending: value }))}
              />
            </Grid>
          </Grid>

          <Divider />
          <AgoraSettingsFieldItem
            fieldName={t('settings.name')}
            value={name}
            setValue={(value: string) => setSupplier((prevState) => ({ ...prevState, name: value }))}
            handleSave={() => handleUpdateSupplier('companyDetails.name', name)}
            handleCancel={reset}
          />
          <Divider />

          <AgoraSettingsFieldItem
            fieldName={t('settings.address')}
            value={address}
            setValue={(value: string) => setSupplier((prevState) => ({ ...prevState, address: value }))}
            handleSave={() => handleUpdateSupplier('companyDetails.address', address)}
            handleCancel={reset}
          />
          <Divider />
          <AgoraSettingsFieldItem
            fieldName={t('settings.city')}
            value={city}
            setValue={(value: string) => setSupplier((prevState) => ({ ...prevState, city: value }))}
            handleSave={() => handleUpdateSupplier('companyDetails.city', city)}
            handleCancel={reset}
          />
          <Divider />
          <AgoraSettingsFieldItem
            fieldName={t('settings.postCode')}
            value={postCode}
            setValue={(value: string) => setSupplier((prevState) => ({ ...prevState, postCode: value }))}
            handleSave={() => handleUpdateSupplier('companyDetails.postCode', postCode)}
            handleCancel={reset}
          />
          <Divider />
          <AgoraSettingsFieldItem
            fieldName={t('settings.phone')}
            value={phone}
            setValue={(value: string) => setSupplier((prevState) => ({ ...prevState, phone: value }))}
            handleSave={() => handleUpdateSupplier('supplierDetails.phone', phone)}
            handleCancel={reset}
          />
          <Divider />
          <AgoraSettingsFieldItem
            fieldName={t('contactInfo.afm')}
            value={afm}
            setValue={(value: string) => setSupplier((prevState) => ({ ...prevState, afm: value }))}
            handleSave={() => handleUpdateSupplier('companyDetails.afm', afm)}
            handleCancel={reset}
          />
          <Divider />
        </>
      )}
      <Grid container className={classes.box} justify={'space-between'}>
        <Box className={classes.warningBox}>
          <Typography style={typo(16, 'bold')}>{t('actions')}</Typography>
        </Box>
        <Box display={'flex'} alignItems={'center'}>
          <Box className={clsx(classes.editOptions, classes.warningBox)} onClick={onClickDeleteSupplier}>
            <CloseIcon className={clsx(classes.editIcon)} />
            {t('settings.deleteSupplier')}
          </Box>
        </Box>
      </Grid>
      <DialogCancel
        open={openDeleteModal}
        title={t('delete', { val: ';' })}
        firstButton={t('accept')}
        secondButton={t('cancel')}
        handleClose={handleCloseDelete}
        yes={handleAcceptDelete}
        no={handleCloseDelete}
      />
    </Container>
  );
};

export default SupplierSettingsPage;
