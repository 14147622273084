import React from 'react';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { typo } from '../../utils/typo';
import TrashIcon from '@material-ui/icons/DeleteOutline';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: '1px solid #E3E3E300',
    height: 0,
    transition: 'all 0.4s ease-out',
    position: 'relative',
    overflow: 'hidden',
  },
  contentContainer: {
    padding: theme.spacing(2, 4),
  },
  visible: {
    borderTop: '1px solid #E3E3E3',
    height: 60,
  },
  textLink: {
    color: '#C6C6C6',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    '&:hover': {
      color: theme.palette.success.main,
    },
    marginRight: theme.spacing(4),
  },
  icon: {
    width: 20,
    marginRight: theme.spacing(0.5),
  },
}));

type HiddenTableActionsProps = {
  visible: boolean;
  deleteSelected?: () => void;
};

const HiddenTableActions: React.FC<HiddenTableActionsProps> = ({ visible, deleteSelected }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={clsx(classes.root, visible && classes.visible)}>
      <Box className={classes.contentContainer}>
        <Grid container wrap={'nowrap'}>
          {deleteSelected && (
            <Box style={{ display: 'inline-block' }} onClick={deleteSelected}>
              <Grid item container className={classes.textLink} wrap={'nowrap'} alignItems={'center'}>
                <TrashIcon className={classes.icon} />
                <Typography style={typo(16)}>{t('deleteSelected')}</Typography>
              </Grid>
            </Box>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default HiddenTableActions;
