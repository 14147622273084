import { Box, Container, Grid, makeStyles } from '@material-ui/core';
import CustomTableContainer from '../../components/table/CustomTableContainer';
import CustomTableHead from '../../components/table/CustomTableHead';
import CustomTableBody from '../../components/table/CustomTableBody';
import { AgoraPaginationFilter, AgoraSearchInput } from 'ui-components';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { setLayout } from '../../redux/slices/layoutSlice';
import { RootState } from '../../redux/store';
import { useDebounce } from '../../hooks/useDebounce';
import { useTranslation } from 'react-i18next';
import { getProducts, setProducts } from '../../redux/slices/buyerSlice';
import InnerProductFavouriteTable from '../../components/table/InnerProductFavouriteTable';
import { getStoreIdThunk, putStoreThunk } from '../../redux/slices/storeSlice';

const useStyles = makeStyles(() => ({
  root: {
    paddingBottom: 93,
  },
}));

const RESULTS_PER_PAGE = 10;

type BuyersFavouritesPageProps = unknown;
const BuyersFavouritesPage: React.FC<BuyersFavouritesPageProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { products, productsCount, productsLoading } = useSelector((state: RootState) => state.buyer.data);
  const { selectedStore } = useSelector((state: RootState) => state.store.data);
  const [sortingName, setSortingName] = useState<boolean | null>(null);
  const [storeId, setStoreId] = useState<string>('');
  const [page, setPage] = useState(0);
  const [searchInput, setSearchInput] = useState<string>('');
  const debouncedSearchText = useDebounce(searchInput, 500);

  // Layout Config Effect
  useEffect(() => {
    dispatch(setLayout({ header: true, mainMenu: true, footer: true }));
    dispatch(setProducts({ products: [] }));
  }, [dispatch]);

  useEffect(() => {
    const splitPath = window.location.pathname.split('/')[2];
    setStoreId(splitPath);
  }, []);

  //setStore
  useEffect(() => {
    if (storeId) {
      dispatch(getStoreIdThunk(storeId));
    }
  }, [dispatch, storeId]);

  useEffect(() => {
    let localSort = '';
    if (sortingName === true) {
      localSort = 'name';
    } else if (sortingName === false) {
      localSort = '-name';
    }
    if (storeId) {
      dispatch(
        getProducts({
          storeId: storeId,
          searchText: debouncedSearchText ? debouncedSearchText : undefined,
          skip: page * RESULTS_PER_PAGE,
          limit: RESULTS_PER_PAGE,
          sort: localSort,
        }),
      );
    }
  }, [dispatch, storeId, page, sortingName, debouncedSearchText]);

  const updateFavourites = (newFav: string[]) => {
    if (selectedStore) {
      const params = { ...selectedStore, favouriteProducts: newFav };
      dispatch(putStoreThunk(params));
    }
  };

  const getTable = () => {
    return (
      <CustomTableContainer>
        <CustomTableHead>
          <Grid container justify={'space-between'} alignItems={'center'} wrap={'nowrap'}>
            <Grid item />
            <Grid item container style={{ flex: 0 }} wrap={'nowrap'} justify={'flex-end'}>
              <Grid item>
                <AgoraSearchInput
                  border={'1px solid #E3E3E3'}
                  placeholder={t('search.products')}
                  changeSearch={setSearchInput}
                  scroll={false}
                />
              </Grid>
              <Grid item>
                <AgoraPaginationFilter
                  page={page}
                  setPage={setPage}
                  pageSize={RESULTS_PER_PAGE}
                  allCount={productsCount}
                  style={{ marginLeft: 16 }}
                />
              </Grid>
            </Grid>
          </Grid>
        </CustomTableHead>
        <CustomTableBody>
          <InnerProductFavouriteTable
            products={products}
            loading={productsLoading}
            favourites={selectedStore ? selectedStore.favouriteProducts : []}
            updateFavourites={updateFavourites}
            sortingName={sortingName}
            setSortingName={setSortingName}
          />
        </CustomTableBody>
      </CustomTableContainer>
    );
  };

  return (
    <Container maxWidth={'lg'} className={classes.root}>
      <Box mt={6}>{getTable()}</Box>
    </Container>
  );
};

export default BuyersFavouritesPage;
