import { Box, Container, Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AgoraPaginationFilter, AgoraSearchInput } from 'ui-components';
import CustomTableBody from '../../components/table/CustomTableBody';
import CustomTableContainer from '../../components/table/CustomTableContainer';
import CustomTableHead from '../../components/table/CustomTableHead';
import StoresInnerTable from '../../components/table/StoresInnerTable';
import TableTabs from '../../components/table/TableTabs';
import { useDebounce } from '../../hooks/useDebounce';
import { setLayout } from '../../redux/slices/layoutSlice';
import { getStoresBySupplier } from '../../redux/slices/supplierSlice';
import { RootState } from '../../redux/store';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
}));

const RESULTS_PER_PAGE = 10;

type SupplierClientsPageProps = unknown;

const SupplierClientsPage: React.FC<SupplierClientsPageProps> = ({}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [tab, setTab] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [searchInput, setSearchInput] = useState<string>('');
  const [sortName, setSortName] = useState<boolean | null>(null);

  const debouncedSearchText = useDebounce(searchInput, 500);

  const clients = useSelector((state: RootState) => state.supplier.stores);
  const loading = useSelector((state: RootState) => state.supplier.loading);
  const maxClientsCount = useSelector((state: RootState) => state.supplier.pagination.stores);

  const tabs = [t('active.clients')];

  // Layout Config Effect
  useEffect(() => {
    dispatch(setLayout({ header: true, mainMenu: true, footer: true }));
  }, [dispatch]);

  useEffect(() => {
    const supplierId = location.pathname.split('/')[2];
    let sort = '';
    if (sortName !== null) {
      sort += sortName ? 'name' : '-name';
    }

    dispatch(
      getStoresBySupplier({
        reqParams: { skip: page * RESULTS_PER_PAGE, limit: RESULTS_PER_PAGE, sort, search: debouncedSearchText },
        supplierId,
      }),
    );
  }, [dispatch, page, tab, sortName, debouncedSearchText]);

  return (
    <Container maxWidth={'lg'}>
      <Box className={classes.root}>
        <CustomTableContainer>
          <CustomTableHead>
            <Grid container justify={'space-between'} alignItems={'center'} wrap={'nowrap'}>
              <Grid item>
                <TableTabs tabs={tabs} selectedTab={tab} setSelectedTab={setTab} />
              </Grid>
              <Grid item container style={{ flex: 0 }} wrap={'nowrap'} justify={'flex-end'}>
                <Grid item>
                  <AgoraSearchInput
                    border={'1px solid #E3E3E3'}
                    placeholder={t('search.store')}
                    changeSearch={setSearchInput}
                    scroll={false}
                  />
                </Grid>
                <Grid item>
                  <AgoraPaginationFilter
                    page={page}
                    setPage={setPage}
                    pageSize={RESULTS_PER_PAGE}
                    allCount={maxClientsCount}
                    style={{ marginLeft: 16 }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </CustomTableHead>
          <CustomTableBody>
            <StoresInnerTable clients={clients} sortName={sortName} setSortName={setSortName} loading={loading} />
          </CustomTableBody>
        </CustomTableContainer>
      </Box>
    </Container>
  );
};

export default SupplierClientsPage;
