import React from 'react';
import {
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { typo } from '../../utils/typo';
import { AgoraToggleSort } from 'ui-components';
import { ReactComponent as PenIcon } from '../../assets/svgs/pen.svg';
import { ReactComponent as TrashCanIcon } from '../../assets/svgs/trash-can.svg';
import { Buyer } from 'types-library';
import CountStoreCell from './tableCellBuyer/CountStoreCell';
import { useTranslation } from 'react-i18next';
import { dispatch } from '../..';
import { openToast } from '../../redux/slices/appSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    '& hr': {
      display: 'inline',
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: '#E3E3E3',
    },
  },
  body: {
    '& > *:last-child .MuiTableCell-root': {
      borderBottom: 'unset',
    },
    '& .MuiTableCell-root': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  row: {
    '& .MuiTableCell-root:not(:last-child)': {
      borderRight: '1px solid #E3E3E3',
    },
  },
  toggleSelect: {
    position: 'relative',
    right: -12,
  },
  selectCell: {
    height: '100%',
    marginRight: theme.spacing(1),
    borderRight: '1px solid #E3E3E3',
  },
  extraSpace: {
    minWidth: 250,
  },
  storeImg: {
    width: 40,
    height: 40,
    maxWidth: 40,
    maxHeight: 40,
    overflow: 'hidden',
    border: '1px solid ' + theme.palette.primary.main,
    borderRadius: '40%',
    marginRight: theme.spacing(1),
  },
  actionButton: {
    textAlign: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    background: theme.palette.primary.main,
    margin: theme.spacing(0, 1),
    padding: theme.spacing(1, 3),
    borderRadius: 8,
  },
}));

type InnerBuyerTableProps = {
  clients: Buyer[];
  deleteBuyer: (id: string) => void;
  editBuyer: (buyer: Buyer) => void;
  toggleName: null | boolean;
  setToggleName: (name: boolean) => void;
  loading?: boolean;
};

const InnerBuyerTable: React.FC<InnerBuyerTableProps> = ({
  clients,
  deleteBuyer,
  editBuyer,
  toggleName,
  setToggleName,
  loading,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const getCellHeaderContent = (params: {
    text: string;
    onClick?: (object: any) => void;
    extra?: boolean;
    state?: null | boolean;
  }) => {
    return (
      <Grid
        container
        wrap={'nowrap'}
        justify={'space-between'}
        className={params.extra ? classes.extraSpace : undefined}
      >
        <Grid item>
          <Typography style={typo(12, 'bold')}>{params.text}</Typography>
        </Grid>
        {params.onClick && (
          <Grid item>
            <AgoraToggleSort onClick={params.onClick} state={params.state} />
          </Grid>
        )}
      </Grid>
    );
  };

  const getTableHead = () => {
    return (
      <TableHead style={{ background: '#F6F6F6' }}>
        <TableRow className={classes.row}>
          <TableCell>
            <Grid container wrap={'nowrap'} alignItems={'center'}>
              {getCellHeaderContent({ text: t('buyer-id') })}
            </Grid>
          </TableCell>
          <TableCell>
            <Grid container wrap={'nowrap'} alignItems={'center'}>
              {getCellHeaderContent({ text: t('name.buyer'), onClick: setToggleName, state: toggleName })}
            </Grid>
          </TableCell>
          <TableCell>{getCellHeaderContent({ text: t('stores') })}</TableCell>
          <TableCell>{getCellHeaderContent({ text: 'Email' })}</TableCell>
          <TableCell>{getCellHeaderContent({ text: t('actions') })}</TableCell>
        </TableRow>
      </TableHead>
    );
  };

  const getRow = (buyer: Buyer) => {
    const { name = '', user = '' } = buyer ? buyer : {};
    return (
      <TableRow className={classes.row}>
        <TableCell>
          <Typography
            style={{ ...typo(12, 'bold'), cursor: 'pointer' }}
            onClick={() => {
              navigator.clipboard.writeText(buyer._id || '');
              dispatch(openToast({ text: 'Copied' }));
            }}
          >
            {buyer._id}
          </Typography>
        </TableCell>
        <TableCell padding={'none'}>
          <Grid container wrap={'nowrap'} style={{ padding: 8 }}>
            <Grid item container>
              <Grid item xs={12}>
                <Typography style={typo(14, 'semi')}>{name}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>{<CountStoreCell buyerId={buyer._id} />}</TableCell>
        <TableCell>
          <Typography style={typo(12, 'bold')}>{user ? user.email : '-'}</Typography>
        </TableCell>

        <TableCell>
          <Grid container justify="center" alignItems="center">
            <Grid item>
              <IconButton
                onClick={() => {
                  editBuyer(buyer);
                }}
              >
                <PenIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <Divider orientation="vertical" />
            </Grid>

            <Grid item>
              <IconButton
                onClick={() => {
                  buyer._id ? deleteBuyer(buyer._id) : null;
                }}
              >
                <TrashCanIcon />
              </IconButton>
            </Grid>

            {/*<Divider orientation="vertical" />*/}
            {/*<IconButton onClick={viewClient}>*/}
            {/*  <EyeIcon />*/}
            {/*</IconButton>*/}
          </Grid>
        </TableCell>
      </TableRow>
    );
  };

  const getTableBody = () => {
    return (
      <TableBody className={classes.body}>
        {!loading ? (
          clients.length === 0 ? (
            <TableRow>
              <TableCell padding={'none'} colSpan={5}>
                <Grid container style={{ padding: '16px 8px', textAlign: 'center' }} justify={'center'}>
                  <Typography style={{ ...typo(16, 'regular'), color: '#999' }}>{t('msg.data')}</Typography>
                </Grid>
              </TableCell>
            </TableRow>
          ) : (
            clients.map((client, index) => <React.Fragment key={index}>{getRow(client)}</React.Fragment>)
          )
        ) : (
          <TableRow>
            <TableCell padding={'none'} colSpan={5}>
              <Grid container style={{ padding: '16px 8px', textAlign: 'center' }} justify={'center'}>
                <CircularProgress style={{ width: 24, height: 24, color: '#999' }} />
              </Grid>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    );
  };

  return (
    <Table className={classes.root}>
      {getTableHead()}
      {getTableBody()}
    </Table>
  );
};

export default InnerBuyerTable;
