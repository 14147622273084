import axios from 'axios';
import Cookies from 'js-cookie';
import { dispatch } from '../../../index';
import { setToken } from '../../../redux/slices/authSlice';

const URL = process.env['REACT_APP_API_URL'];

const MASTER_KEY = process.env['REACT_APP_MASTER_KEY'];

const axiosInstance = axios.create({
  baseURL: URL,
  timeoutErrorMessage: 'Request took long to complete, times up!',
});

axiosInstance.interceptors.request.use(
  (config) => {
    config.headers.masterkey = MASTER_KEY;
    const token = Cookies.get('adminToken'); //store.getState().authentication.accessToken; //connect with redux store
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async (error) => {
    const originalRequest = error.config;
    if ((error.response.status === 401 || error.message.status === 403) && !originalRequest._retry) {
      Cookies.remove('adminToken');
      dispatch(setToken(null));
      console.error('Axios returned error status:', error.response.status);
    }
    return Promise.reject(error);
  },
);

// eslint-disable-next-line
export const postRequest = (endpoint: string, body?: any): Promise<any> => {
  return axiosInstance.post(endpoint, { ...body }, {});
};

// eslint-disable-next-line
export const putRequest = (endpoint: string, body?: any): Promise<any> => {
  return axiosInstance.put(endpoint, { ...body }, {});
};

// eslint-disable-next-line
export const getRequest = (endpoint: string, params?: any): Promise<any> => {
  return axiosInstance.get(endpoint, { params });
};

// eslint-disable-next-line
export const deleteRequest = (endpoint: string, params?: any, paramsToBody?: boolean): Promise<any> => {
  if (paramsToBody) {
    return axiosInstance.delete(endpoint, { data: params });
  }
  return axiosInstance.delete(endpoint, { params });
};
